import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { IDealership } from '../../models/dealership.interface';

@Injectable()
export class DealershipService {

  private dealerships: IDealership[];
  public isPreloginFlow: boolean = false;
  private url: string;

  constructor(private http: HttpClient) { }

  public findByPdn(pdn: string, stateCode?: string): Observable<IDealership[]> {
    if(this.isPreloginFlow){
      this.url = `${environment.ndaServerURLS.dealerServices}/public/dealers?pdnNumber=${pdn}&stateCode=${stateCode}`;
      this.isPreloginFlow = false;
    }
    else{
      this.url = `${environment.ndaServerURLS.dealerServices}/dealers?pdnNumber=${pdn}`;
    }
    return this.findDealerships(this.url);
  }

  public findByDealershipName(dealershipName: string, stateId: string): Observable<IDealership[]> {
    if(this.isPreloginFlow){
      this.url = `${environment.ndaServerURLS.dealerServices}/public/dealers?dealershipName=${dealershipName}&stateCode=${stateId}`;
      this.isPreloginFlow = false;
    }
    else{
      this.url = `${environment.ndaServerURLS.dealerServices}/dealers?dealershipName=${dealershipName}&stateCode=${stateId}`;
    }
    return this.findDealerships(this.url);
  }

  private findDealerships(url: string): Observable<IDealership[]> {
    return this.http.get(url)
      .map(response => response['resources']);
  }
}
