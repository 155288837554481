import * as tslib_1 from "tslib";
import { NgZone } from "@angular/core";
import "rxjs/add/operator/finally";
import allytm from "@allysf/allytm";
import { IDigitalDataOptionsPage, CustomEventBody, } from "./analytics.interface";
import * as _ from "lodash";
var AnalyticsService = /** @class */ (function () {
    function AnalyticsService(doc, ngZone) {
        this.doc = doc;
        this.ngZone = ngZone;
        this.pageInstanceId = "{environment}-_-{pageName}";
        this.version = "Ally_1.4";
        this.user = {};
        allytm();
        window["digitalData"] = {
            pageInstanceId: this.pageInstanceId,
            delayPageLoadEvent: true,
            loadRules: {
                adobeAnalytics: true,
                adobeAudienceManager: true,
                adobeTarget: false,
                celebrus: false,
                confirmit: true,
                googleAnalytics: false,
                livePerson: true,
            },
            page: {
                pageInfo: [
                    {
                        pageID: "",
                        pageName: "",
                        destinationURL: document.location.href,
                        referringURL: document.referrer,
                        sysEnv: window.orientation ? "mobile" : "desktop",
                        language: document.getElementsByTagName("html").item(0)
                            ? document
                                .getElementsByTagName("html")
                                .item(0)
                                .lang.substring(0, 2)
                            : "Unknown",
                    },
                ],
                category: {
                    primaryCategory: "ADOS",
                    site: "ADOS",
                    section: "",
                    subsection: "",
                    lineOfBusiness: "Auto Dealer",
                },
                attributes: {},
            },
            product: [],
            cart: {},
            transaction: {},
            event: [],
            component: [],
            user: {},
            privacy: {},
            version: this.version,
        };
    }
    // Custom events, only errors.
    AnalyticsService.prototype.trackEndPoint = function (data) {
        var _this = this;
        try {
            this.ngZone.runOutsideAngular(function () {
                // window['allytm']['event']('customEvent', data.errors);
                _this.ngZone.run(function () {
                    console.log("trackEndPoint");
                });
            });
        }
        catch (error) {
            console.error("TAGGING_SERVICE_NOT_LOADED_PROPERLY");
        }
    };
    // Only errors.
    AnalyticsService.prototype.trackError = function (error) {
        var _this = this;
        try {
            this.ngZone.runOutsideAngular(function () {
                window["allytm"]["event"]("customError", error);
                _this.ngZone.run(function () {
                    console.log("trackError", error);
                });
            });
        }
        catch (error) {
            console.error("TAGGING_SERVICE_NOT_LOADED_PROPERLY");
        }
    };
    // Modal view events.
    AnalyticsService.prototype.trackModal = function (title, body) {
        var _this = this;
        try {
            this.ngZone.runOutsideAngular(function () {
                if (window["digitalData"])
                    window["allytm"]["event"]("modalView", title, {
                        treatAsPageview: true,
                        msg: body,
                    });
                _this.ngZone.run(function () {
                    console.log("trackModal - ", title);
                });
            });
        }
        catch (error) {
            console.error("TAGGING_SERVICE_NOT_LOADED_PROPERLY");
        }
    };
    // Custom Search events, outside of errors.
    AnalyticsService.prototype.trackEvent = function (title, attributes) {
        var _this = this;
        try {
            this.ngZone.runOutsideAngular(function () {
                window["allytm"]["event"](title, attributes);
                _this.ngZone.run(function () {
                    console.log("trackEvent - ", title);
                });
            });
        }
        catch (error) {
            console.error("TAGGING_SERVICE_NOT_LOADED_PROPERLY");
        }
    };
    // Page view events.
    AnalyticsService.prototype.trackPage = function (data) {
        var _this = this;
        try {
            this.ngZone.runOutsideAngular(function () {
                if (window["digitalData"])
                    window["allytm"]["event"]("pageview", data.title, tslib_1.__assign({}, data.attributes, { user: _this.userObject }));
                _this.ngZone.run(function () {
                    console.log("trackPage - ", data.title);
                });
            });
        }
        catch (error) {
            console.error("TAGGING_SERVICE_NOT_LOADED_PROPERLY");
        }
    };
    // Click events
    AnalyticsService.prototype.trackClick = function (tagName, name) {
        var _this = this;
        try {
            this.ngZone.runOutsideAngular(function () {
                window["allytm"]["clickEvent"]({ tagName: tagName, name: name });
                _this.ngZone.run(function () {
                    console.log("trackClick - ", name);
                });
            });
        }
        catch (error) {
            console.error("TAGGING_SERVICE_NOT_LOADED_PROPERLY");
        }
    };
    AnalyticsService.prototype.updateUserObject = function (user) {
        // Store user object if nonempty
        if (!_.isEmpty(user)) {
            sessionStorage.setItem("taggingUser", JSON.stringify(user));
            this.user = user;
        }
    };
    Object.defineProperty(AnalyticsService.prototype, "userObject", {
        // Return user object from session storage
        get: function () {
            return JSON.parse(sessionStorage.getItem("taggingUser"));
        },
        enumerable: true,
        configurable: true
    });
    return AnalyticsService;
}());
export { AnalyticsService };
