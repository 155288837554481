import {Injectable, NgZone} from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, flatMap, tap } from 'rxjs/operators';
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';

import { StoreService } from '../services/store.service';
import { AppConstantsService } from '../services/app-constants.service'
import { BookmarkingService } from '../services/bookmarking.service';
import { CookieService } from 'ngx-cookie';
import { ProfileUserType, ProfileAllyRole } from '../models/profile.interface';
import { SpinnerService } from '../loading-spinner/services/spinner/spinner.service';
import { get } from 'lodash';
import { Observer } from 'rxjs';
import { SessionManagementService, IBranchUserRequestStatus } from '../services/session-management.service';
import {OktaAuthService} from "@nda/app/services";
import { LaunchDarklyService } from '../services/launchdarkly/launchdarkly.service';

type CanActivateResponse = Observable<boolean> | Promise<boolean> | boolean;

type OktaAuthorizeStatus = keyof {
  'access_denied', 'login_required'
}
namespace OktaAuthorizeStatus {
  export const AccessDenied: OktaAuthorizeStatus = 'access_denied';
  export const LoginRequired: OktaAuthorizeStatus = 'login_required';
}

@Injectable()
export class AppGuard implements CanActivate {

  _subscription: any;
  allLDFlags: Object;
  showDM: boolean;
  showADR: boolean;
  useSCAlternateUrl: boolean;
  isFirstRun: boolean = true;
  currentUrl: string;
  authorizationError: string;

  constructor(
    private router: Router,
    private sessionManager: SessionManagementService,
    private appConstantsService: AppConstantsService,
    private storeService: StoreService,
    private bookmarkingService: BookmarkingService,
    private oktaAuthService: OktaAuthService,
    private cookieService: CookieService,
    private spinnerService: SpinnerService,
    private zone: NgZone,
    private ld: LaunchDarklyService
  ) {
    // Fetches LD flags, keeps track of changes, and stores them in session service
    this._subscription = this.ld.flagChange.subscribe((flags) => {
      this.allLDFlags = flags;
      this.showDM = flags['document-manager'].current;
      this.showADR = flags['dash_app_adr'].current;
      this.useSCAlternateUrl = flags['dash_app_sc'].current;
      this.storeService.write('allLaunchDarklyFlags', this.allLDFlags);
      this.storeService.write('launchDarklyDMFlag', this.showDM);
      this.storeService.write('launchDarklyADRFlag', this.showADR);
      this.storeService.write('launchDarklySCFlag', this.useSCAlternateUrl);
    });
  }

  get isAuthorized(): boolean {
    const profile = this.sessionManager.profile;

    if (profile && this.sessionManager.hasSession) {
      const authorizedUserD = profile.userType == ProfileUserType.dealership;
      const authorizedUserB = profile.userType == ProfileUserType.branch && !!profile.allyRole;

      return authorizedUserD || authorizedUserB;
    }

    return false;
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): CanActivateResponse {
    this.currentUrl = state.url;

    // handle external authentication & keep them on the login page
    if (!!this.cookieService.get('ext_auth') && this.currentUrl == this.appConstantsService.login_path) {
      return true;
    }

    // halt the user & delete their session
    else if (this.sessionManager.willDeleteSessionOnNextVisit) {
      this.sessionManager.willDeleteSessionOnNextVisit = false;

      return this.sessionManager.deleteSession()
        .map(() => false)
        .finally(() => this.redirectToLogin());
    }

    // If Apigee Redirect URL triggers app guard, stop execution of app guard.
    let browserUrl = window.location.href;
    if (browserUrl.includes("?code=") && browserUrl.includes("&scope=") && browserUrl.includes("&state=")) {
      return false;
    }

    // Check for Okta profile existence, first page load, and whether Apigee details are in session storage.
    /*
      Note: Only for the following scenario do we want to call refresh session endpoint. This handles +1 app logout.
        this.isAuthorized == true
        this.isFirstRun == true
        this.oktaAuthService.getApigeeJwt() == false
    */
    const isAuthorized = this.isAuthorized && this.isFirstRun && !!!this.oktaAuthService.getApigeeJwt();
    const request = isAuthorized ? this.sessionManager.refreshSession() : Observable.of([]);

    return request
    // set flags
      .do(() => {this.isFirstRun = false;})
      .do(() => this.authorizationError = null)

      // handle authorization
      .mergeMap(() => this.authorize())
      .catch(error => {
        this.authorizationError = error.errorCode;
        return Observable.of([]);
      })

      // get additional user info & navigate user
      .mergeMap(() => this.requestStatus())
      .mergeMap(status => this.validate(status))

      // hide loading state
      .do(() => this.spinnerService.hide());
  }

  authorize(): Observable<any> {
    if (this.isAuthorized && !localStorage.getItem('proxyLayerObject')) {
      this.oktaAuthService.ApigeeRedirect();
    } else if (this.isAuthorized) {
      return Observable.of([]);
    }

    return Observable.create((observer: Observer<any>) => {
      this.sessionManager.authorize()
        .then(() => observer.next([]))
        .catch(error => observer.error(error));
    })
  }

  requestStatus(): Promise<any> {


    const isAuthorizedBranchUser = !this.authorizationError && this.sessionManager.hasSession && this.sessionManager.isBranchUser;
    const isNoRoleOrAllyUserRole = !get(this.sessionManager.profile, 'allyRole');
    //  || get(this.sessionManager.profile, 'allyRole') === ProfileAllyRole.user;

    return new Promise(resolve => {
      if (isAuthorizedBranchUser && isNoRoleOrAllyUserRole) {
        let userName = get(this.sessionManager.profile, 'login');
        return this.sessionManager.requestStatus(userName)
        .subscribe((requestStatus: IBranchUserRequestStatus) => resolve(requestStatus));
      }

      return resolve({ pendingRequests: false, pendingApprovalRequests: false });
    });
  }

  validate(requestStatus): Observable<boolean> {
    const IS_LOGIN  = this.currentUrl == this.appConstantsService.login_path,
          IS_NDA    = !this.cookieService.get('ext_app_name'),
          NEEDS_APPROVAL = !this.sessionManager.hasBirthright;

    if (IS_NDA  && IS_LOGIN && !NEEDS_APPROVAL && !this.authorizationError) {
      this.redirectToDashboard();
    }
    else if (IS_NDA  && !IS_LOGIN && NEEDS_APPROVAL && !!this.sessionManager.profile) {
      if(!this.sessionManager.profile.allyRole && this.sessionManager.isDealershipUser){
        this.sessionManager.deleteSessionOktaSesion().subscribe(()=>{
          this.redirectToLogin({ isPendingApproval: true });
        });

      }
      if(!this.currentUrl.includes('/pending-requests') && !this.sessionManager.profile.allyRole && this.sessionManager.isBranchUser){
        this.redirectToAdminRegistration();
      }
    }
    else if (!this.sessionManager.hasSession && !IS_LOGIN) {
      this.bookmarkingService.setBookmarkedUrl(this.currentUrl);
      this.redirectToLogin();
      return Observable.of(false);
    }
    else if (this.sessionManager.hasSession) {
      if (this.sessionManager.isBranchUser && !this.sessionManager.profile.allyRole) {
        if (requestStatus.pendingApprovalRequests) {
          this.redirectToPendingRequests({ hasApprovalRequests: true });
        }
        else {
          this.redirectToAdminRegistration();
        }
      }
      else if (this.sessionManager.isBranchUser && IS_LOGIN) {
        this.redirectToDashboard();
      }
      else if (!this.sessionManager.isBranchUser && NEEDS_APPROVAL && !IS_LOGIN) {
        this.sessionManager.deleteSessionOktaSesion().subscribe(()=>{
          this.redirectToLogin({ isPendingApproval: true });
        });

        return Observable.of(false);
      }
      else if (this.sessionManager.isDealershipUser && IS_LOGIN) {
        this.redirectToDashboard();
      }
    }
    else if (this.authorizationError == OktaAuthorizeStatus.AccessDenied) {
      return Observable.create(obs => {
        this.sessionManager.requestSessionDetails()
          .then(data => {
            if (data.status == 'ACTIVE') {
              let login = data.login;
              this.sessionManager.requestStatus(login, true)
                .subscribe((requestStatus: IBranchUserRequestStatus) => {
                  if (requestStatus.pendingRequests) {
                    this.redirectToError({ type: 'pendingRequests' });
                  }
                  else {
                    this.redirectToRegistration({ type: 'ext', login });
                  }
                  obs.next(false);
                });
            }
            obs.next(true);
          });
      });
    }

    return Observable.of(true);
  }

  redirectToLogin(params = {}): void {
    this.zone.run(()=>{
      this.router.navigate(['/login'], { queryParams: params });
    });
  }
  redirectToDashboard(): void {
    this.zone.run(()=>{
      this.router.navigate(['/dashboard']);
    });
  }
  redirectToRegistration(params = {}): void {
    this.zone.run(()=>{
      this.router.navigate(['/register'], { queryParams: params });
    });
  }
  redirectToAdminRegistration(): void {
    this.zone.run(()=>{
      this.router.navigate(['/register/ally']);
    });
  }
  redirectToError(params = {}): void {
    this.zone.run(()=>{
      this.router.navigate(['/error'], { queryParams: params });
    });
  }
  redirectToPendingRequests(params = {}): void {
    if (this.currentUrl.indexOf('/manage/users/pending-requests') < 0) {
      this.zone.run(()=>{
        this.router.navigate(['/manage/users/pending-requests'], { queryParams: params });
      });
    }
  }
}
