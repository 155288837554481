import { Component, HostListener, Input, ViewChild } from '@angular/core';
import { cloneDeep, sortBy, includes } from 'lodash';
import 'rxjs/add/operator/finally';

import { IApplicationToRequestByAdmin, IRegion } from '../../models/application-to-request-by-admin.interface';;
import { AppConstantsService } from '../../../services/app-constants.service';
import { AppStrings } from '../../../../assets/app-strings/app-strings';
import { DrawerComponentCommunicatorService } from '../../../drawer/services/drawer-component-communicator.service';
import { AppDrawerOutputState, UserDetailsService } from '../../services/user-details/user-details.service';
import { IUserDetails } from '../../models/user-details.interface';
import { get, find } from 'lodash';
import { SmartAuctionDialog } from '../../../manage-users/components/smartauction-dialog/smartauction-dialog.component';
import * as _ from 'lodash';
import { SessionManagementService } from '../../../services/session-management.service';
import { StoreService } from '../../../services';

@Component({
  selector: 'app-nda-modal-content-add-remove-applications',
  templateUrl: './nda-modal-content-add-remove-applications.component.html',
  styleUrls: ['./nda-modal-content-add-remove-applications.component.scss']
})
export class NdaModalContentAddRemoveApplicationsComponent {
  checkTheBox: boolean = false;
  drawerState: boolean = false;
  profile: IUserDetails;
  shouldShowSmartAuctionRoles: boolean = false;
  shouldShowSmartCashRoles: boolean = false;
  shouldShowAllyRideReturnRoles: boolean = false;
  shouldShowAuctionAccessInfo: boolean = false;
  updateSCRole: string;
  applicationRegions: IRegion[];
  applicationToRequest: IApplicationToRequestByAdmin[];
  isAddSecondaryApps: boolean;
  pdn: string;
  isRequestorAnAdmin: boolean;
  addManageUsersHidden: boolean = false;
  removeManageUsersHidden: boolean = false;
  isOnlyManageUsers: boolean = false;
  public innerWidth: number = 0;
  public smartAuctionAppIndex: number = 0;

  //Set first two initially to "null" to indicate they are not used.
  public secondaryRoleValue: string = null;
  public auctionAccessIDValue: number = null;

  readonly CONSTANTS = {
    ...AppStrings['applicationAndReports'],
    ...AppStrings['addRemoveApps']
  };
  readonly OPTIONS = AppStrings['registration']['dealership']['smartAuctionSecondaryRoles'];

  private hasPendingRequest: boolean = false;

  @ViewChild('selectRequestAccessForm', { static: true }) ngForm;
  @ViewChild("smartAuctionDialog", { static: true }) smartAuctionDialog: SmartAuctionDialog;
  @Input('data') data: any;

  constructor(
    private appConstantsService: AppConstantsService,
    private drawerComponentCommunicatorService: DrawerComponentCommunicatorService,
    private userDetailsService: UserDetailsService,
    protected sessionManager: SessionManagementService,
    private storeService : StoreService,
  ) { }

  get isFormChanged() {
    this.drawerComponentCommunicatorService.isFormChanged = this.isApplicationSelected;
    return true;
  }

  get isApplicationSelected(): boolean {
    return !!this.selectedApplications.length;
  }

  get isSingleApplicationSelected(): boolean {
    return this.selectedApplications.length == 1;
  }

  get selectedApplications(): IApplicationToRequestByAdmin[] {
    return this.applicationToRequest.filter((item) => item['isChecked']);
  }

  get isAllAppsSelected(): boolean {
    return this.applicationToRequest.length === this.applicationToRequest.filter((item) => item['isChecked']).length;
  }

  get isAllyRole(): boolean {
    return !!this.userDetailsService.allyRole;
  }

  get smartAuctionRoles(): string[] {
    if (this.isAllyRole) {
      return this.appConstantsService.smartAuctionAllyUserRoles;
    };
    return this.appConstantsService.smartAuctionDealershipRoles;
  }

  get smartCashRoles(): string[] {
    if (this.isAllyRole) {
      return this.appConstantsService.smartCashAllyUserRoles;
    };
    return null;
  }

  get smartCashAllyUserRegion(): string[] {
    if (this.isAllyRole) {
      return this.appConstantsService.smartCashAllyUserRegion;
    };
    return null;
  }

  get allyRideReturnRoles(): string[] {
    if (this.isAllyRole) {
      return null;
    };
    return this.appConstantsService.allyRideReturnDealershipRoles;
  }

  get isNoAppsAvailableToRequest() {
    return (this.isAddAppsDrawer && this.applicationToRequest.length === 0) 
     || (this.isAddAppsDrawer && this.isOnlyManageUsers && this.addManageUsersHidden);
  }

  get isNoAppsAvailableToRemove() {
    return (this.isRemoveAppsDrawer && this.applicationToRequest.length === 0) 
     || (this.isRemoveAppsDrawer && this.isOnlyManageUsers && this.removeManageUsersHidden);
  }

  get isAddAppsDrawer() {
    return this.drawerComponentCommunicatorService.drawerComponentIdentifier === this.drawerComponentCommunicatorService.addAppsIdentifier;
  }

  get isRemoveAppsDrawer() {
    return this.drawerComponentCommunicatorService.drawerComponentIdentifier === this.drawerComponentCommunicatorService.removeAppsIdentifier;
  }

  get isSubmitVisible() {
    return !this.isNoAppsAvailableToRequest && !this.isNoAppsAvailableToRemove;
  }

  get isSecondaryAddApps() {
    return this.isAddSecondaryApps;
  }

  public auctionAccessIdErrorMessage(submitted, invalid, minLength){
    if (!!submitted && !!invalid && !minLength){
      return this.CONSTANTS.invalidAuctionAccessIDError;
    }
    if(!!submitted && !!minLength){
      return this.CONSTANTS.minlengthAuctionAccessIDError;
    }else{return "";}
  }

  ngOnInit() {
    this.ngForm.resetForm();
    this.innerWidth = window.innerWidth;
    this.shouldShowSmartAuctionRoles = false;
    this.shouldShowSmartCashRoles = false;
    this.shouldShowAllyRideReturnRoles = false;
    this.applicationRegions = get(this, 'data.applicationRegions', []);
    this.applicationToRequest = sortBy(cloneDeep(this.data.applicationList), 'name');
    this.pdn = this.data.pdn;
    this.isAddSecondaryApps = this.data.isAddSecondaryApps;
    this.profile = this.data.profile;
    this.isRequestorAnAdmin = this.data.isRequestorAnAdmin;

    // Add 'secondaryRole' and 'auctionAccessID' fields to the form locally if SmartAuction is an available app.
    for (let i = 0; i < this.applicationToRequest.length; i++) {
      if (this.applicationToRequest[i].name === 'SmartAuction') {
        this.smartAuctionAppIndex = i;
        this.applicationToRequest[i]['secondaryRole'] = null;
        this.applicationToRequest[i]['auctionAccessId'] = null;
      };
      // If "Manage Users" application is found in the applicationToRequest array
      if (this.applicationToRequest[i].name === this.appConstantsService.allyAdminApps[0]['name']) {
        if(!this.sessionManager.showAddManageUserApp) {
            this.addManageUsersHidden = true;
        }
        if(!this.sessionManager.showRemoveManageUserApp) {
            this.removeManageUsersHidden = true;
        }
        if(this.applicationToRequest.length === 1) {
            this.isOnlyManageUsers = true;
        }
      };
    // Logic to remove an application from the Manage User, Search User, Primary and Secondary Add List based on LD flag being false
      let ldAppListFalse: [] = this.storeService.read('ldAppListFalse');
      let ldAppShow = ldAppListFalse.find(item => item === this.applicationToRequest[i].name);

      if (ldAppShow !== undefined)  {
          this.applicationToRequest.splice(i, 1);
      };
    };
  }

  ngOnDestroy() {
    this.ngForm.resetForm();
  }

  // Boolean function to display or hide Manage Users from 'Add' and 'Remove' in 'APPLICATIONS & REPORTS'
  isSelectable(appObj) {
    if((this.addManageUsersHidden && this.removeManageUsersHidden) 
    || (this.addManageUsersHidden && !this.removeManageUsersHidden) 
    || (!this.addManageUsersHidden && this.removeManageUsersHidden)) {
        return appObj.name !== this.appConstantsService.allyAdminApps[0]['name'];
    }
    else {
        return appObj;
    }
  }

  showConfirmationBanner() {
    this.userDetailsService.callOnloadService = true;
    if (this.isSecondaryAddApps) {
      this.userDetailsService.updateState(null);
    }
    else {
      this.userDetailsService.updateState(this.isSingleApplicationSelected ? AppDrawerOutputState.processSingleAddAppSuccess : AppDrawerOutputState.processMultipleAddAppSuccess);
    }
  }

  public handlerSubmitForm() {
    let request;

    // If admin application has SmartAuction, add AuctionAccessID value to it.
    for (var i = 0; i < this.selectedApplications.length; i++) {
      if (this.selectedApplications[i].name === 'SmartAuction') {
        this.selectedApplications[i].auctionAccessId = this.auctionAccessIDValue;
      }
    }


    this.userDetailsService.selectedApps = this.selectedApplications;
    this.userDetailsService.isAllAppsSelected = this.isAllAppsSelected;

    if (this.isRemoveAppsDrawer) {
      this.userDetailsService.displayRemoveAppModal = true;
    }
    else if (this.isRequestorAnAdmin) {
      request = this.userDetailsService.addApplication(this.isSecondaryAddApps)
    }
    else {
      request = this.userDetailsService.addApplicationViaWorkflow(this.isSecondaryAddApps, this.profile)

    }

    if (request && !this.hasPendingRequest) {
      this.hasPendingRequest = true;

      request
        .finally(() => this.hasPendingRequest = false)
        .subscribe(response => this.processServiceResponse(response));
    }
  }

  /**
   * processServiceResponse - interpret service response to shows banners accordingly
   * @param response
   */
  public processServiceResponse(response): void {
    if (response && response['error'] && response['error']['exceptions']) {
      this.showErrorBanner();
    }
    else {
      this.showConfirmationBanner();
    }
  }

  showErrorBanner(): void {
    this.userDetailsService.updateState(AppDrawerOutputState.processError);
  }

  get isDealerRole(): boolean {
    return this.userDetailsService.isDealerRole;
  }

  toggleRoleSelect(application: IApplicationToRequestByAdmin) {

    application.role = application.region = "";

    if (this.isSmartAuction(application.name)) {
      this.shouldShowSmartAuctionRoles = !this.shouldShowSmartAuctionRoles;
    }
    else if (this.isSmartCash(application.name) && this.isAllyRole) {
      this.shouldShowSmartCashRoles = !this.shouldShowSmartCashRoles;
    }

    if (this.isAllyRideReturn(application.name) && this.isDealerRole) {
      application.role = '';
      this.shouldShowAllyRideReturnRoles = !this.shouldShowAllyRideReturnRoles;
    }
  }

  isSmartAuction(applicationName: string): boolean {
    return applicationName === this.appConstantsService.dealerAppNames['SA'];
  }

  isSmartCash(applicationName: string): boolean {
    return applicationName === this.appConstantsService.dealerAppNames['SC'];
  }

  isAllyRideReturn(applicationName: string): boolean {
    return applicationName === this.appConstantsService.dealerAppNames['SG'];
  }

  showRolesSection(app: IApplicationToRequestByAdmin): boolean {
    return app.isChecked && get(app, 'roles.length', 0) > 0;
  }

  showRegionSection(app: IApplicationToRequestByAdmin): boolean {
    const role = find(app.roles, role => role.roleName === app.role);
    return app.isChecked && get(role, 'hasRegion') === 'Y';
  }

  showRolesSectionForSmartCash(applicationName: string): boolean {
    if (this.isRemoveAppsDrawer) {
      return false;
    }
    return this.isSmartCash(applicationName) && this.isAllyRole && this.shouldShowSmartCashRoles;
  }

  showRolesSectionForAllyRideReturn(applicationName: string): boolean {
    if (this.isRemoveAppsDrawer) {
      return false;
    }
    return this.isAllyRideReturn(applicationName) && this.isDealerRole && this.shouldShowAllyRideReturnRoles;
  }

  selectSmartAuctionRole(event: any): boolean {
    return !!event.target.value;
  }

  selectSmartCashRole(event: any): boolean {
    return !!(this.updateSCRole = event.target.value);
  }

  selectAllyRideReturnRole(event: any): boolean {
    return !!event.target.value;
  }

  showRegionForSmartCash(role: string): boolean {
    const roleWithoutRegion = ["EO", "EO Basic", "Support", ""];
    return !includes(roleWithoutRegion, role);
  }

  // Changes secondaryRole based on chosen option in select list.
  updateSecondaryRolename(event): void {
    this.applicationToRequest[this.smartAuctionAppIndex]['secondaryRole'] = event.target.value;
    this.secondaryRoleValue = event.target.value;
  }

  // Changes auctionAccessID numeric value based on current input (only allows numbers).
  updateAuctionAccessID(event): void {
    this.applicationToRequest[this.smartAuctionAppIndex]['auctionAccessId'] = event.target.value;
    this.auctionAccessIDValue = event.target.value;
  }

  // Show secondary roles if given app has roles AND has selected name 'Dealer'.
  showSecondaryRolesSection(app: IApplicationToRequestByAdmin): boolean {
    return this.showRolesSection(app) && app.role == 'Dealer';
  }

  // Show 100 M number (AuctionACCESS ID) input field if given app has roles AND has selected name 'Buyer' and/or 'Buyer and Seller'.
  showAuctionAccessIDField(app: IApplicationToRequestByAdmin): boolean {
    return this.showSecondaryRolesSection(app) && this.shouldShowAuctionAccessID();
  }

  // Helper function to determine if AuctionAccess ID field should be shown based on which option is selected.
  shouldShowAuctionAccessID(): boolean {
    if (this.secondaryRoleValue == 'Buyer' || this.secondaryRoleValue == 'Buyer and Seller') {
      return true;
    } else {
      // If field is not shown, purge values so they are not submitted.
      this.applicationToRequest[this.smartAuctionAppIndex]['auctionAccessId'] = null;
      this.auctionAccessIDValue = null;
      return false;
    }
  }

  determineTooltipMargin(): void {
    var element = document.getElementById("tooltipHolder");

    // If page size shrinks to mobile view, purge massive margins to make the view less silly.
    if (this.innerWidth < 768) {
      element.style.removeProperty("margin-top");
      element.style.removeProperty("margin-left");
    };

    // If page size grows past mobile view, re-add massive margins to make the view correct again.
    if (this.innerWidth >= 768 && this.innerWidth < 1200) {
      element.style.marginTop = '14.5625rem';
      element.style.marginLeft = '-9.25rem';
    };

    // If page grows to widescreen, add this margin size.
    if (this.innerWidth >= 1200) {
      element.style.marginTop = '14.5625rem';
      element.style.marginLeft = '-11.1875rem';
    }
  }

  // Dynamically grab window size as window size is changed.
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;

    if (this.shouldShowAuctionAccessID()) {
      this.determineTooltipMargin();
    };
  }

  // Show AuctionACCESS ID Info Tooltip
  showAuctionAccessInfo(): void {
    this.shouldShowAuctionAccessInfo = true;
  }

  // Receive from child component to hide AuctionACCESS ID Info Tooltip
  hideAuctionAccessInfo(val: boolean): void {
    this.shouldShowAuctionAccessInfo = val;
  }

  // Note: SmartAuction secondary roles will only appear for dealers, hard-coded here via role name equalling 'Dealer'.
  get smartAuctionSecondaryRoles() {
    return this.appConstantsService.smartAuctionDealershipSecondaryRoles;
  }

  // Problem-child Firefox requires this function to restrict input to only numbers and backspace + direction arrows.
  filterInput(event: any) {
    let numberEntered = false;
    // Input number entered or one of the 4 directtion up, down, left and right.
    if ((event.which >= 48 && event.which <= 57) || (event.which >= 37 && event.which <= 40)) {
      numberEntered = true;
    }
    else {
      // Input entered that is *not* of direction arrows, delete, or backspace.
      if (!((event.keyCode >= 37 && event.keyCode <= 40) || event.keyCode == 46 || event.which == 8)) {
        event.preventDefault();
      }
    }
  }

}
