import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanDeactivate } from '@angular/router';
import {Observable} from 'rxjs/Observable';
import { StoreService } from '../../services/store.service';
import { IFlowMap } from '../../models/flow-map.interface';
import { AppConstantsService } from '../../services/app-constants.service';
import { SessionManagementService } from '../../services/session-management.service';
import { LaunchDarklyService } from '../../services/launchdarkly/launchdarkly.service';

@Injectable()
export class RegisterGuard implements CanActivate {

  _subscription: any;
  allLDFlags: Object;
  showDM: boolean;
  showADR: boolean;

  private flowMap: IFlowMap;

  constructor(
    private router: Router,
    private storeService: StoreService,
    private appConstantsService: AppConstantsService,
    private sessionManager: SessionManagementService,
    private ld: LaunchDarklyService,
  ) {
    // Fetches LD flags, keeps track of changes, and stores them in session service
    this._subscription = this.ld.flagChange.subscribe((flags) => {
      this.allLDFlags = flags;
      this.showDM = flags['document-manager'].current;
      this.showADR = flags['dash_app_adr'].current;
      this.storeService.write('allLaunchDarklyFlags', this.allLDFlags);
      this.storeService.write('launchDarklyDMFlag', this.showDM);
      this.storeService.write('launchDarklyADRFlag', this.showADR);
    });

    this.flowMap = this.appConstantsService.pdn_non_verified_register_flow;
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

      if (this.sessionManager.willDeleteSessionOnNextVisit) {
        this.router.navigate(['/login']);
        return false;
      }

      return true;
  }
}
