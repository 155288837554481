import { Injectable } from '@angular/core';

@Injectable()
export class AppListService {

  constructor() {}

  public filterAppsByLdFlags(app, allLaunchDarklyFlags, applications, ldAppListFalse) {
    if(app.properties.useLDFlag && app.properties.useLDFlag.includes('Y')) { // Checks database for USE_LD_FLAG = 'Y' to proceed with LaunchDarkly logic
      if(app.properties.ldEnableAppName){ // Checks database for LD_ENABLE_APP_NAME to be defined to proceed with LaunchDarkly logic
        let ldEnableAppName = app.properties.ldEnableAppName;
        if(allLaunchDarklyFlags[ldEnableAppName].current == false) { // Checks LD flags for LD_ENABLE_APP_NAME to be false to proceed with LD logic
          ldAppListFalse.push(app.properties.displayName); // Stores name of application with LD flag as false in array to be used in session storage for comparison
          applications.pop(); // If application is LD enabled and has the LD flag set to false, will remove the application from the list
        }
      }  
    }
  }

}

