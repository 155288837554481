import { OnInit } from '@angular/core';
import { filter, cloneDeep, startsWith, includes, get, sortBy } from 'lodash';
import 'rxjs/add/operator/finally';
import { AppConstantsService } from '../../../services/app-constants.service';
import { AppStrings } from '../../../../assets/app-strings/app-strings';
import { AddApplicationReportsService } from '../../services/add-application-reports.service';
import { DrawerComponentCommunicatorService } from '../../../drawer/services/drawer-component-communicator.service';
import { AddAppsService } from '../../services/add-apps.service';
import { SmartAuctionDialog } from '../smartauction-dialog/smartauction-dialog.component';
import { ViewAddApplicationsService } from '../../services/view-add-applications.service';
import { SessionManagementService } from '../../../services/session-management.service';
import { StoreService } from '../../../services';
export var PdnTypePrefix;
(function (PdnTypePrefix) {
    PdnTypePrefix.admin = 'ADM_';
    PdnTypePrefix.dealer = 'DLR_';
})(PdnTypePrefix || (PdnTypePrefix = {}));
var NdaAddApplicationReportsComponent = /** @class */ (function () {
    function NdaAddApplicationReportsComponent(sessionManagementService, appConstantsService, addApplicationReportsService, drawerCommunicatorService, addAppsService, viewAddApplicationsService, storeService) {
        this.sessionManagementService = sessionManagementService;
        this.appConstantsService = appConstantsService;
        this.addApplicationReportsService = addApplicationReportsService;
        this.drawerCommunicatorService = drawerCommunicatorService;
        this.addAppsService = addAppsService;
        this.viewAddApplicationsService = viewAddApplicationsService;
        this.storeService = storeService;
        this.checkTheBox = false;
        this.drawerState = false;
        this.shouldShowSmartAuctionRoles = false;
        this.shouldShowSmartCashRoles = false;
        this.shouldShowAllyRideReturnRoles = false;
        this.shouldShowAuctionAccessButton = false;
        this.shouldShowAuctionAccessInfo = false;
        this.smartCashRoleName = '';
        this.smartCashRegionName = '';
        this.allyRideReturnRoleName = '';
        this.CONSTANTS = AppStrings['applicationAndReports'];
        this.OPTIONS = AppStrings['registration']['dealership']['smartAuctionSecondaryRoles'];
        this.smartAuctionAppIndex = 0;
        this.innerWidth = 0;
        this.hasPendingRequest = false;
        //Set first two initially to "null" to indicate they are not used.
        this.secondaryRoleValue = null;
        this.auctionAccessIDValue = null;
        this.groupsList = this.viewAddApplicationsService.groupsList;
    }
    NdaAddApplicationReportsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.innerWidth = window.innerWidth;
        this.isAddSecondaryApps = this.data['isAddSecondaryApps'];
        this.profileObj = this.data['profileObj'];
        this.secondaryDealershipName = this.data['secondaryDealershipName'];
        this.shouldShowSmartAuctionRoles = false;
        this.shouldShowSmartCashRoles = false;
        this.shouldShowAllyRideReturnRoles = false;
        this.shouldShowAuctionAccessButton = false;
        this.shouldShowAuctionAccessInfo = false;
        this.applicationToRequest = cloneDeep(sortBy(this.data['applicationToRequest'], 'applicationName'));
        var _loop_1 = function (i) {
            if (this_1.applicationToRequest[i].applicationName === 'SmartAuction') {
                this_1.smartAuctionAppIndex = i;
                this_1.applicationToRequest[i]['secondaryRole'] = null;
                this_1.applicationToRequest[i]['auctionAccessId'] = null;
            }
            ;
            // Logic to remove an application from the Edit Profile, Primary and Secondary Add List based on LD flag being false
            var ldAppListFalse = this_1.storeService.read('ldAppListFalse');
            var ldAppShow = ldAppListFalse.find(function (item) { return item === _this.applicationToRequest[i].applicationName; });
            if (ldAppShow !== undefined) {
                this_1.applicationToRequest.splice(i, 1);
            }
        };
        var this_1 = this;
        // Add 'secondaryRole' and 'auctionAccessID' fields to the form locally if SmartAuction is an available app.
        for (var i = 0; i < this.applicationToRequest.length; i++) {
            _loop_1(i);
        }
        ;
    };
    NdaAddApplicationReportsComponent.prototype.handlerSubmitForm = function () {
        var _this = this;
        if (this.selfAdminCheck()) {
            this.addApplicationReportsService.getPdnAdminEmails(this.data['pdn'])
                .subscribe(function (response) {
                if (response['resource']) {
                    _this.adminEmails = response['resource']['adminMails'];
                    if (_this.adminEmails && _this.adminEmails.length === 1 && _this.adminEmails[0] === _this.sessionManagementService.profile.email) {
                        _this.callAddApplicationsOktaService();
                    }
                    else {
                        _this.callWorkflowAddAppsService();
                    }
                }
            });
        }
        else {
            this.callWorkflowAddAppsService();
        }
    };
    /**
     * callWorkflowAddAppsService - To call workflow add apps service
     */
    NdaAddApplicationReportsComponent.prototype.callWorkflowAddAppsService = function () {
        var _this = this;
        var request;
        if (this.isAddSecondaryApps) {
            request = this.addApplicationReportsService.addSecondaryApps(this.profileObj, this.processSecondaryApps(this.selectedApplications, this.data['pdn']), this.data['pdn'], this.secondaryDealershipName);
        }
        else {
            request = this.addApplicationReportsService.postApplicationRequest(this.selectedApplications, this.data['pdn']);
        }
        if (this.hasPendingRequest) {
            return;
        }
        this.hasPendingRequest = true;
        request
            .finally(function () { return _this.hasPendingRequest = false; })
            .subscribe(function (response) { return _this.processAddAppsResponse(response); });
    };
    /**
     * callAddApplicationsOktaService - to call okta add apps service
     */
    NdaAddApplicationReportsComponent.prototype.callAddApplicationsOktaService = function () {
        var _this = this;
        if (this.hasPendingRequest) {
            return;
        }
        this.hasPendingRequest = true;
        this.addApplicationReportsService.addApplication(this.data['pdn'], this.isAddSecondaryApps, this.secondaryDealershipName, this.selectedApplications)
            .finally(function () { return _this.hasPendingRequest = false; })
            .subscribe(function (response) { return _this.processAddAppsResponse(response); });
    };
    /**
     * processAddAppsResponse - Process service response
     * @param response
     */
    NdaAddApplicationReportsComponent.prototype.processAddAppsResponse = function (response) {
        if (response == null || !get(response, 'error.exceptions')) {
            this.addAppsService.isAppAccessRequest = 'Success';
        }
        else {
            this.addAppsService.isAppAccessRequest = 'Error';
        }
    };
    NdaAddApplicationReportsComponent.prototype.processSecondaryApps = function (apps, pdn) {
        var processedList = [];
        var applicationToRequestList;
        for (var i = 0; i < apps.length; i++) {
            applicationToRequestList = {
                applicationName: apps[i].applicationName,
                oeId: pdn,
                oeIdType: 'secondary',
                isChecked: apps[i].isChecked,
                roleName: apps[i].roleName,
            };
            processedList.push(applicationToRequestList);
        }
        return processedList;
    };
    Object.defineProperty(NdaAddApplicationReportsComponent.prototype, "isApplicationSelected", {
        get: function () {
            return !!this.selectedApplications.length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaAddApplicationReportsComponent.prototype, "selectedApplications", {
        get: function () {
            return this.applicationToRequest.filter(function (item) { return item['isChecked']; });
        },
        enumerable: true,
        configurable: true
    });
    NdaAddApplicationReportsComponent.prototype.showRegionSection = function (item) {
        return item.isChecked && item.selectedRole['hasRegion'] == 'Y';
    };
    Object.defineProperty(NdaAddApplicationReportsComponent.prototype, "isRoleWithoutRegion", {
        get: function () {
            return this.smartCashRoleName == "EO" || this.smartCashRoleName == "EO Basic" || this.smartCashRoleName == "Support" || this.smartCashRoleName == "";
        },
        enumerable: true,
        configurable: true
    });
    NdaAddApplicationReportsComponent.prototype.setWarningModalFlag = function () {
        this.drawerCommunicatorService.isFormChanged = this.isApplicationSelected;
    };
    NdaAddApplicationReportsComponent.prototype.updateRolename = function (item) {
        item.roleName = item.roles ? item.selectedRole['roleName'] : '';
    };
    // Changes secondaryRole based on chosen option in select list.
    NdaAddApplicationReportsComponent.prototype.updateSecondaryRolename = function (event) {
        this.applicationToRequest[this.smartAuctionAppIndex]['secondaryRole'] = event.target.value;
        this.secondaryRoleValue = event.target.value;
    };
    // Changes auctionAccessID numeric value based on current input (only allows numbers).
    NdaAddApplicationReportsComponent.prototype.updateAuctionAccessID = function (event) {
        this.applicationToRequest[this.smartAuctionAppIndex]['auctionAccessId'] = event.target.value;
        this.auctionAccessIDValue = event.target.value;
    };
    NdaAddApplicationReportsComponent.prototype.isSmartAuction = function (applicationName) {
        return applicationName === this.appConstantsService.dealerAppNames['SA'];
    };
    // Show roles in dropdown menu if given app has roles.
    NdaAddApplicationReportsComponent.prototype.showRolesSection = function (app) {
        return app.isChecked && app.roles && app.roles.length > 0;
    };
    NdaAddApplicationReportsComponent.prototype.selectSmartAuctionRole = function (event) {
        return !!event.target.value;
    };
    NdaAddApplicationReportsComponent.prototype.selectSmartAuctionSecondaryRole = function (event) {
        return !!event.target.value;
    };
    NdaAddApplicationReportsComponent.prototype.isSmartCash = function (applicationName) {
        return applicationName === this.appConstantsService.dealerAppNames['SC'];
    };
    NdaAddApplicationReportsComponent.prototype.isAllyRideReturn = function (applicationName) {
        return applicationName === this.appConstantsService.dealerAppNames['SG'];
    };
    NdaAddApplicationReportsComponent.prototype.selectSmartCashRole = function (event) {
        this.updateSCRole = event.target.value;
        return !!event.target.value;
    };
    NdaAddApplicationReportsComponent.prototype.selectAllyRideReturnRole = function (event) {
        return !!event.target.value;
    };
    // Show secondary roles if given app has roles AND has selected name 'Dealer'.
    NdaAddApplicationReportsComponent.prototype.showSecondaryRolesSection = function (app) {
        return this.showRolesSection(app) && app.roleName == 'Dealer';
    };
    // Show 100 M number (AuctionACCESS ID) input field if given app has roles AND has selected name 'Buyer' and/or 'Buyer and Seller'.
    NdaAddApplicationReportsComponent.prototype.showAuctionAccessIDField = function (app) {
        return this.showSecondaryRolesSection(app) && this.shouldShowAuctionAccessID();
    };
    // Helper function to determine if AuctionAccess ID field should be shown based on which option is selected.
    NdaAddApplicationReportsComponent.prototype.shouldShowAuctionAccessID = function () {
        if (this.secondaryRoleValue == 'Buyer' || this.secondaryRoleValue == 'Buyer and Seller') {
            return true;
        }
        else {
            // If field is not shown, purge values so they are not submitted.
            this.applicationToRequest[this.smartAuctionAppIndex]['auctionAccessId'] = null;
            this.auctionAccessIDValue = null;
            return false;
        }
        ;
    };
    NdaAddApplicationReportsComponent.prototype.determineTooltipMargin = function () {
        var element = document.getElementById("tooltipHolder");
        // If page size shrinks to mobile view, purge massive margins to make the view less silly.
        if (this.innerWidth < 768) {
            element.style.removeProperty("margin-top");
        }
        ;
        // If page size grows past mobile view, re-add massive margins to make the view correct again.
        if (this.innerWidth >= 768) {
            element.style.marginTop = '14.5625rem';
        }
        ;
    };
    // Dynamically grab window size as window size is changed.
    NdaAddApplicationReportsComponent.prototype.onResize = function (event) {
        this.innerWidth = window.innerWidth;
        if (this.shouldShowAuctionAccessID()) {
            this.determineTooltipMargin();
        }
        ;
    };
    // Show AuctionACCESS ID Info Tooltip
    NdaAddApplicationReportsComponent.prototype.showAuctionAccessInfo = function () {
        this.shouldShowAuctionAccessInfo = true;
    };
    // Receive from child component to hide AuctionACCESS ID Info Tooltip
    NdaAddApplicationReportsComponent.prototype.hideAuctionAccessInfo = function (val) {
        this.shouldShowAuctionAccessInfo = val;
    };
    Object.defineProperty(NdaAddApplicationReportsComponent.prototype, "smartAuctionRoles", {
        get: function () {
            if (this.sessionManagementService.isBranchUser) {
                return this.appConstantsService.smartAuctionAllyUserRoles;
            }
            ;
            return this.appConstantsService.smartAuctionDealershipRoles;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaAddApplicationReportsComponent.prototype, "smartAuctionSecondaryRoles", {
        // Note: SmartAuction secondary roles will only appear for dealers.
        get: function () {
            if (this.sessionManagementService.isDealershipUser) {
                return this.appConstantsService.smartAuctionDealershipSecondaryRoles;
            }
            ;
            return null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaAddApplicationReportsComponent.prototype, "smartCashRoles", {
        get: function () {
            if (this.sessionManagementService.isBranchUser) {
                return this.appConstantsService.smartCashAllyUserRoles;
            }
            ;
            return null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaAddApplicationReportsComponent.prototype, "smartCashAllyUserRegion", {
        get: function () {
            if (this.sessionManagementService.isBranchUser) {
                return this.appConstantsService.smartCashAllyUserRegion;
            }
            ;
            return null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaAddApplicationReportsComponent.prototype, "allyRideReturnRoles", {
        get: function () {
            if (this.sessionManagementService.isDealershipUser) {
                return this.appConstantsService.allyRideReturnDealershipRoles;
            }
            ;
            return null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NdaAddApplicationReportsComponent.prototype, "hasUnassignedApplications", {
        get: function () {
            return this.applicationToRequest ? this.applicationToRequest.length > 0 : false;
        },
        enumerable: true,
        configurable: true
    });
    NdaAddApplicationReportsComponent.prototype.getAdminPdns = function () {
        this.admGroups = filter(this.groupsList, function (groups) {
            return startsWith(groups.name, PdnTypePrefix.admin);
        });
    };
    //this function will check whether the loogged in user is admin for the requested dealership
    NdaAddApplicationReportsComponent.prototype.selfAdminCheck = function () {
        this.getAdminPdns();
        var userPdn = this.data['pdn'];
        this.adminPdns = this.admGroups.filter(function (group) { return includes(group.name, userPdn); });
        return this.adminPdns && this.adminPdns.length > 0;
    };
    // Problem-child Firefox requires this function to restrict input to only numbers and backspace + direction arrows.
    NdaAddApplicationReportsComponent.prototype.filterInput = function (event) {
        var numberEntered = false;
        // Input number entered or one of the 4 directtion up, down, left and right.
        if ((event.which >= 48 && event.which <= 57) || (event.which >= 37 && event.which <= 40)) {
            numberEntered = true;
        }
        else {
            // Input entered that is *not* of direction arrows, delete, or backspace.
            if (!((event.keyCode >= 37 && event.keyCode <= 40) || event.keyCode == 46 || event.which == 8)) {
                event.preventDefault();
            }
        }
    };
    NdaAddApplicationReportsComponent.prototype.addAppsErrorMessage = function (submitted, invalid) {
        if (!!submitted && !!invalid) {
            return this.CONSTANTS.selectARole;
        }
        else {
            return "";
        }
    };
    NdaAddApplicationReportsComponent.prototype.secondaryRoleErrorMessage = function (submitted, invalid) {
        if (!!submitted && !!invalid) {
            return this.CONSTANTS.selectARole;
        }
        else {
            return "";
        }
    };
    NdaAddApplicationReportsComponent.prototype.selectAccessErrorMessage = function (submitted, invalid, minLength) {
        if (!!submitted && !!invalid && !minLength) {
            return this.CONSTANTS.invalidAuctionAccessIDError;
        }
        if (!!submitted && !!minLength) {
            return this.CONSTANTS.minlengthAuctionAccessIDError;
        }
        else {
            return "";
        }
    };
    NdaAddApplicationReportsComponent.prototype.formErrorMessage = function (submitted) {
        if (!!submitted && !this.isApplicationSelected) {
            return this.CONSTANTS.selectARole;
        }
        else {
            return "";
        }
    };
    return NdaAddApplicationReportsComponent;
}());
export { NdaAddApplicationReportsComponent };
