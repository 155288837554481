import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs/Observable';
import { flatMap, finalize } from 'rxjs/operators';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/throw';
import { catchError } from 'rxjs/operators';
import { HttpErrorHandlerService } from './http-error-handler.service';
import { StoreService } from './store.service';
import { Subject } from 'rxjs/Subject';
import { ProfileUserType, ProfileDealerRole, ProfileAllyRole, ProfileBirthrightAccess, ProfileRideReturnGroup } from '../models/profile.interface';
import { assign, filter, find, get, includes, map, pick, values, startsWith } from 'lodash';
import { OktaAuthService } from './okta-auth/okta-auth.service';
import { AnalyticsService } from '.';
var SessionPersistenceAttrKey;
(function (SessionPersistenceAttrKey) {
    SessionPersistenceAttrKey.HasActiveSession = 'hasActiveSession';
    SessionPersistenceAttrKey.WillDeleteSession = 'willDeleteSession';
})(SessionPersistenceAttrKey || (SessionPersistenceAttrKey = {}));
var SessionManagementAttrKey;
(function (SessionManagementAttrKey) {
    SessionManagementAttrKey.profile = "profile";
    SessionManagementAttrKey.sessionId = "sessionId";
    SessionManagementAttrKey.sessionToken = "sessionToken";
    SessionManagementAttrKey.sessionDetails = "sessionDetails";
    SessionManagementAttrKey.allyUserProperties = "allyUserProperties";
    SessionManagementAttrKey.helpDeskProperties = "helpDeskProperties";
    SessionManagementAttrKey.allyAdminProperties = "allyAdminProperties";
    SessionManagementAttrKey.allLaunchDarklyFlags = "allLaunchDarklyFlags";
    SessionManagementAttrKey.launchDarklyDMFlag = "launchDarklyDMFlag";
    SessionManagementAttrKey.launchDarklyADRFlag = "launchDarklyADRFlag";
    SessionManagementAttrKey.launchDarklySCFlag = "launchDarklySCFlag";
})(SessionManagementAttrKey || (SessionManagementAttrKey = {}));
export var ExternalUserGroup;
(function (ExternalUserGroup) {
    ExternalUserGroup.Ally = 'Ally Users';
    ExternalUserGroup.Mitsubishi = 'Mitsubishi Users';
})(ExternalUserGroup || (ExternalUserGroup = {}));
var SessionManagementService = /** @class */ (function () {
    function SessionManagementService(httpErrorHandler, http, storeService, oktaAuthService, analytics) {
        var _this = this;
        this.http = http;
        this.storeService = storeService;
        this.oktaAuthService = oktaAuthService;
        this.analytics = analytics;
        this.hasSession$ = new Subject();
        this.httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        this.storeService.write(SessionPersistenceAttrKey.HasActiveSession, true);
        window.addEventListener('storage', function (evt) { return _this.handleStorageChange(evt.key); });
        this.handleError = httpErrorHandler.createHandleError('refreshSession');
    }
    Object.defineProperty(SessionManagementService.prototype, "willDeleteSessionOnNextVisit", {
        get: function () {
            return this.storeService.read(SessionPersistenceAttrKey.WillDeleteSession);
        },
        set: function (willDelete) {
            if (willDelete) {
                this.storeService.write(SessionPersistenceAttrKey.WillDeleteSession, willDelete);
            }
            else {
                this.storeService.delete(SessionPersistenceAttrKey.WillDeleteSession);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "hasSession", {
        get: function () {
            return !!this.sessionId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "hasBirthright", {
        get: function () {
            return includes(values(ProfileBirthrightAccess), get(this.profile, 'birthright'));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "profile", {
        get: function () {
            var profile = this.storeService.read(SessionManagementAttrKey.profile);
            return !!profile ? profile && tslib_1.__assign({}, profile, { userName: profile.login }) : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "userName", {
        get: function () {
            return get(this.profile, 'login');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "sessionId", {
        get: function () {
            return this.storeService.read(SessionManagementAttrKey.sessionId);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "sessionToken", {
        get: function () {
            return this.storeService.read(SessionManagementAttrKey.sessionToken);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "sessionDetails", {
        get: function () {
            return pick(this.profile, 'userName', 'userType', 'userId');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "isBranchUser", {
        get: function () {
            return get(this.profile, 'userType') == ProfileUserType.branch;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "isDealershipUser", {
        get: function () {
            return get(this.profile, 'userType') == ProfileUserType.dealership;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allLaunchDarklyFlags", {
        // Used for getting all the LD flags from storage
        get: function () {
            return this.storeService.read(SessionManagementAttrKey.allLaunchDarklyFlags);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "launchDarklyDM", {
        // Used for getting the LD Document-Manager flag from storage
        get: function () {
            return this.storeService.read(SessionManagementAttrKey.launchDarklyDMFlag);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "launchDarklyADR", {
        // Used for getting the LD dash_app_adr flag from storage
        get: function () {
            return this.storeService.read(SessionManagementAttrKey.launchDarklyADRFlag);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "launchDarklySC", {
        // Used for getting the LD dash_app_sc flag from storage
        get: function () {
            return this.storeService.read(SessionManagementAttrKey.launchDarklySCFlag);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "canManage", {
        // This is triggering Manage User hidden or not
        get: function () {
            if (this.isBranchUser) {
                var roles = [ProfileAllyRole.admin, ProfileAllyRole.helpdesk];
                return includes(roles, get(this.profile, 'allyRole'))
                    || get(this.branchUserRequestStatus, 'pendingApprovalRequests');
            }
            else if (this.isDealershipUser) {
                return get(this.profile, 'dealerRole') == ProfileDealerRole.admin;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "canSearch", {
        get: function () {
            return this.canManage && !(get(this.profile, 'allyRole') === ProfileAllyRole.user);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "externalGroups", {
        get: function () {
            if (this.profile !== null) {
                return this.profile['exernalGroups'] || [];
            }
            return [];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyAdminProperties", {
        /*
          GETTERS for manageUser from allyUserPorperties in session
        */
        get: function () {
            return this.storeService.read(SessionManagementAttrKey.allyAdminProperties);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "helpDeskProperties", {
        get: function () {
            return this.storeService.read(SessionManagementAttrKey.helpDeskProperties);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyUserProperties", {
        get: function () {
            return this.storeService.read(SessionManagementAttrKey.allyUserProperties);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyAdminManageUser", {
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.allyAdminProperties);
            return obj.manageUser;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyAdminManageUserBoolean", {
        get: function () {
            if (this.allyAdminManageUser == 'Y') {
                return true;
            }
            if (this.allyAdminManageUser == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "helpDeskManageUser", {
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.helpDeskProperties);
            return obj.manageUser;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "helpDeskManageUserBoolean", {
        get: function () {
            if (this.helpDeskManageUser == 'Y') {
                return true;
            }
            if (this.helpDeskManageUser == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyUserManageUser", {
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.allyUserProperties);
            return obj.manageUser;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyUserManageUserBoolean", {
        get: function () {
            if (this.allyUserManageUser == 'Y') {
                return true;
            }
            if (this.allyUserManageUser == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "showManage", {
        // Checks the role in profile and returns boolean based on info from allyUserProperties
        get: function () {
            if (this.isBranchUser) {
                var role = get(this.profile, 'allyRole');
                if ((role == ProfileAllyRole.admin) && this.allyAdminManageUserBoolean) {
                    return true;
                }
                if ((role == ProfileAllyRole.helpdesk) && this.helpDeskManageUserBoolean) {
                    return true;
                }
                if ((role == ProfileAllyRole.user) && this.allyUserManageUserBoolean) {
                    return true;
                }
                return false;
            }
            if (this.isDealershipUser) {
                return get(this.profile, 'dealerRole') == ProfileDealerRole.admin;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyAdminSearchUser", {
        /*
          GETTERS for searchUser from allyUserPorperties in session
        */
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.allyAdminProperties);
            return obj.searchUser;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyAdminSearchUserBoolean", {
        get: function () {
            if (this.allyAdminSearchUser == 'Y') {
                return true;
            }
            if (this.allyAdminSearchUser == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "helpDeskSearchUser", {
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.helpDeskProperties);
            return obj.searchUser;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "helpDeskSearchUserBoolean", {
        get: function () {
            if (this.helpDeskSearchUser == 'Y') {
                return true;
            }
            if (this.helpDeskSearchUser == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyUserSearchUser", {
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.allyUserProperties);
            return obj.searchUser;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyUserSearchUserBoolean", {
        get: function () {
            if (this.allyUserSearchUser == 'Y') {
                return true;
            }
            if (this.allyUserSearchUser == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "showSearch", {
        get: function () {
            if (this.isBranchUser) {
                var role = get(this.profile, 'allyRole');
                if ((role == ProfileAllyRole.admin) && this.allyAdminSearchUserBoolean) {
                    return true;
                }
                if ((role == ProfileAllyRole.helpdesk) && this.helpDeskSearchUserBoolean) {
                    return true;
                }
                if ((role == ProfileAllyRole.user) && this.allyUserSearchUserBoolean) {
                    return true;
                }
                return false;
            }
            if (this.isDealershipUser) {
                return true;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyAdminEditDealerUserInfo", {
        /*
        GETTERS for editDealerUserInfo from allyUserPorperties in session
        */
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.allyAdminProperties);
            return obj.editDealerUserInfo;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyAdminEditDealerUserInfoBoolean", {
        get: function () {
            if (this.allyAdminEditDealerUserInfo == 'Y') {
                return true;
            }
            if (this.allyAdminEditDealerUserInfo == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "helpDeskEditDealerUserInfo", {
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.helpDeskProperties);
            return obj.editDealerUserInfo;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "helpDeskEditDealerUserInfoBoolean", {
        get: function () {
            if (this.helpDeskEditDealerUserInfo == 'Y') {
                return true;
            }
            if (this.helpDeskEditDealerUserInfo == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyUserEditDealerUserInfo", {
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.allyUserProperties);
            return obj.editDealerUserInfo;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyUserEditDealerUserInfoBoolean", {
        get: function () {
            if (this.allyUserEditDealerUserInfo == 'Y') {
                return true;
            }
            if (this.allyUserEditDealerUserInfo == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "showEditDealerUserInfo", {
        get: function () {
            if (this.isBranchUser) {
                var role = get(this.profile, 'allyRole');
                if ((role == ProfileAllyRole.admin) && this.allyAdminEditDealerUserInfoBoolean) {
                    return true;
                }
                if ((role == ProfileAllyRole.helpdesk) && this.helpDeskEditDealerUserInfoBoolean) {
                    return true;
                }
                if ((role == ProfileAllyRole.user) && this.allyUserEditDealerUserInfoBoolean) {
                    return true;
                }
                return false;
            }
            if (this.isDealershipUser) {
                return true;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyAdminEditAllyUserInfo", {
        /*
        GETTERS for editAllyUserInfo from allyUserPorperties in session
        */
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.allyAdminProperties);
            return obj.editAllyUserInfo;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyAdminEditAllyUserInfoBoolean", {
        get: function () {
            if (this.allyAdminEditAllyUserInfo == 'Y') {
                return true;
            }
            if (this.allyAdminEditAllyUserInfo == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "helpDeskEditAllyUserInfo", {
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.helpDeskProperties);
            return obj.editAllyUserInfo;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "helpDeskEditAllyUserInfoBoolean", {
        get: function () {
            if (this.helpDeskEditAllyUserInfo == 'Y') {
                return true;
            }
            if (this.helpDeskEditAllyUserInfo == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyUserEditAllyUserInfo", {
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.allyUserProperties);
            return obj.editAllyUserInfo;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyUserEditAllyUserInfoBoolean", {
        get: function () {
            if (this.allyUserEditAllyUserInfo == 'Y') {
                return true;
            }
            if (this.allyUserEditAllyUserInfo == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "showEditAllyUserInfo", {
        get: function () {
            if (this.isBranchUser) {
                var role = get(this.profile, 'allyRole');
                if ((role == ProfileAllyRole.admin) && this.allyAdminEditAllyUserInfoBoolean) {
                    return true;
                }
                if ((role == ProfileAllyRole.helpdesk) && this.helpDeskEditAllyUserInfoBoolean) {
                    return true;
                }
                if ((role == ProfileAllyRole.user) && this.allyUserEditAllyUserInfoBoolean) {
                    return true;
                }
                return false;
            }
            if (this.isDealershipUser) {
                return true;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyAdminAddDealerAppReports", {
        /*
        GETTERS for addDealerAppReports from allyUserPorperties in session
        */
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.allyAdminProperties);
            return obj.addDealerAppReports;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyAdminAddDealerAppReportsBoolean", {
        get: function () {
            if (this.allyAdminAddDealerAppReports == 'Y') {
                return true;
            }
            if (this.allyAdminAddDealerAppReports == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "helpDeskAddDealerAppReports", {
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.helpDeskProperties);
            return obj.addDealerAppReports;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "helpDeskAddDealerAppReportsBoolean", {
        get: function () {
            if (this.helpDeskAddDealerAppReports == 'Y') {
                return true;
            }
            if (this.helpDeskAddDealerAppReports == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyUserAddDealerAppReports", {
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.allyUserProperties);
            return obj.addDealerAppReports;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyUserAddDealerAppReportsBoolean", {
        get: function () {
            if (this.allyUserAddDealerAppReports == 'Y') {
                return true;
            }
            if (this.allyUserAddDealerAppReports == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "showAddDealerAppReports", {
        get: function () {
            if (this.isBranchUser) {
                var role = get(this.profile, 'allyRole');
                if ((role == ProfileAllyRole.admin) && this.allyAdminAddDealerAppReportsBoolean) {
                    return true;
                }
                if ((role == ProfileAllyRole.helpdesk) && this.helpDeskAddDealerAppReportsBoolean) {
                    return true;
                }
                if ((role == ProfileAllyRole.user) && this.allyUserAddDealerAppReportsBoolean) {
                    return true;
                }
                return false;
            }
            if (this.isDealershipUser) {
                return true;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyAdminAddManageUserApp", {
        /*
            GETTERS for addManageUserApp from allyUserPorperties in session
            */
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.allyAdminProperties);
            return obj.addManageUserApp;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyAdminAddManageUserAppBoolean", {
        get: function () {
            if (this.allyAdminAddManageUserApp == 'Y') {
                return true;
            }
            if (this.allyAdminAddManageUserApp == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "helpDeskAddManageUserApp", {
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.helpDeskProperties);
            return obj.addManageUserApp;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "helpDeskAddManageUserAppBoolean", {
        get: function () {
            if (this.helpDeskAddManageUserApp == 'Y') {
                return true;
            }
            if (this.helpDeskAddManageUserApp == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyUserAddManageUserApp", {
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.allyUserProperties);
            return obj.addManageUserApp;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyUserAddManageUserAppBoolean", {
        get: function () {
            if (this.allyUserAddManageUserApp == 'Y') {
                return true;
            }
            if (this.allyUserAddManageUserApp == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "showAddManageUserApp", {
        get: function () {
            if (this.isBranchUser) {
                var role = get(this.profile, 'allyRole');
                if ((role == ProfileAllyRole.admin) && this.allyAdminAddManageUserAppBoolean) {
                    return true;
                }
                if ((role == ProfileAllyRole.helpdesk) && this.helpDeskAddManageUserAppBoolean) {
                    return true;
                }
                if ((role == ProfileAllyRole.user) && this.allyUserAddManageUserAppBoolean) {
                    return true;
                }
                return false;
            }
            if (this.isDealershipUser) {
                return true;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyAdminAddAllyUserAppReports", {
        /*
        GETTERS for addAllyUserAppReports from allyUserPorperties in session
        */
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.allyAdminProperties);
            return obj.addAllyUserAppReports;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyAdminAddAllyUserAppReportsBoolean", {
        get: function () {
            if (this.allyAdminAddAllyUserAppReports == 'Y') {
                return true;
            }
            if (this.allyAdminAddAllyUserAppReports == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "helpDeskAddAllyUserAppReports", {
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.helpDeskProperties);
            return obj.addAllyUserAppReports;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "helpDeskAddAllyUserAppReportsBoolean", {
        get: function () {
            if (this.helpDeskAddAllyUserAppReports == 'Y') {
                return true;
            }
            if (this.helpDeskAddAllyUserAppReports == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyUserAddAllyUserAppReports", {
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.allyUserProperties);
            return obj.addAllyUserAppReports;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyUserAddAllyUserAppReportsBoolean", {
        get: function () {
            if (this.allyUserAddAllyUserAppReports == 'Y') {
                return true;
            }
            if (this.allyUserAddAllyUserAppReports == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "showAddAllyUserAppReports", {
        get: function () {
            if (this.isBranchUser) {
                var role = get(this.profile, 'allyRole');
                if ((role == ProfileAllyRole.admin) && this.allyAdminAddAllyUserAppReportsBoolean) {
                    return true;
                }
                if ((role == ProfileAllyRole.helpdesk) && this.helpDeskAddAllyUserAppReportsBoolean) {
                    return true;
                }
                if ((role == ProfileAllyRole.user) && this.allyUserAddAllyUserAppReportsBoolean) {
                    return true;
                }
                return false;
            }
            if (this.isDealershipUser) {
                return true;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyAdminRemoveDealerAppReports", {
        /*
        GETTERS for removeDealerAppReports from allyUserPorperties in session
        */
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.allyAdminProperties);
            return obj.removeDealerAppReports;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyAdminRemoveDealerAppReportsBoolean", {
        get: function () {
            if (this.allyAdminRemoveDealerAppReports == 'Y') {
                return true;
            }
            if (this.allyAdminRemoveDealerAppReports == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "helpDeskRemoveDealerAppReports", {
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.helpDeskProperties);
            return obj.removeDealerAppReports;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "helpDeskRemoveDealerAppReportsBoolean", {
        get: function () {
            if (this.helpDeskRemoveDealerAppReports == 'Y') {
                return true;
            }
            if (this.helpDeskRemoveDealerAppReports == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyUserRemoveDealerAppReports", {
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.allyUserProperties);
            return obj.removeDealerAppReports;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyUserRemoveDealerAppReportsBoolean", {
        get: function () {
            if (this.allyUserRemoveDealerAppReports == 'Y') {
                return true;
            }
            if (this.allyUserRemoveDealerAppReports == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "showRemoveDealerAppReports", {
        get: function () {
            if (this.isBranchUser) {
                var role = get(this.profile, 'allyRole');
                if ((role == ProfileAllyRole.admin) && this.allyAdminRemoveDealerAppReportsBoolean) {
                    return true;
                }
                if ((role == ProfileAllyRole.helpdesk) && this.helpDeskRemoveDealerAppReportsBoolean) {
                    return true;
                }
                if ((role == ProfileAllyRole.user) && this.allyUserRemoveDealerAppReportsBoolean) {
                    return true;
                }
                return false;
            }
            if (this.isDealershipUser) {
                return true;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyAdminRemoveManageUserApp", {
        /*
        GETTERS for removeManageUserApp from allyUserPorperties in session
        */
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.allyAdminProperties);
            return obj.removeManageUserApp;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyAdminRemoveManageUserAppBoolean", {
        get: function () {
            if (this.allyAdminRemoveManageUserApp == 'Y') {
                return true;
            }
            if (this.allyAdminRemoveManageUserApp == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "helpDeskRemoveManageUserApp", {
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.helpDeskProperties);
            return obj.removeManageUserApp;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "helpDeskRemoveManageUserAppBoolean", {
        get: function () {
            if (this.helpDeskRemoveManageUserApp == 'Y') {
                return true;
            }
            if (this.helpDeskRemoveManageUserApp == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyUserRemoveManageUserApp", {
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.allyUserProperties);
            return obj.removeManageUserApp;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyUserRemoveManageUserAppBoolean", {
        get: function () {
            if (this.allyUserRemoveManageUserApp == 'Y') {
                return true;
            }
            if (this.allyUserRemoveManageUserApp == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "showRemoveManageUserApp", {
        get: function () {
            if (this.isBranchUser) {
                var role = get(this.profile, 'allyRole');
                if ((role == ProfileAllyRole.admin) && this.allyAdminRemoveManageUserAppBoolean) {
                    return true;
                }
                if ((role == ProfileAllyRole.helpdesk) && this.helpDeskRemoveManageUserAppBoolean) {
                    return true;
                }
                if ((role == ProfileAllyRole.user) && this.allyUserRemoveManageUserAppBoolean) {
                    return true;
                }
                return false;
            }
            if (this.isDealershipUser) {
                return true;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyAdminRemoveAllyUserAppReports", {
        /*
        GETTERS for removeAllyUserAppReports from allyUserPorperties in session
        */
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.allyAdminProperties);
            return obj.removeAllyUserAppReports;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyAdminRemoveAllyUserAppReportsBoolean", {
        get: function () {
            if (this.allyAdminRemoveAllyUserAppReports == 'Y') {
                return true;
            }
            if (this.allyAdminRemoveAllyUserAppReports == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "helpDeskRemoveAllyUserAppReports", {
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.helpDeskProperties);
            return obj.removeAllyUserAppReports;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "helpDeskRemoveAllyUserAppReportsBoolean", {
        get: function () {
            if (this.helpDeskRemoveAllyUserAppReports == 'Y') {
                return true;
            }
            if (this.helpDeskRemoveAllyUserAppReports == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyUserRemoveAllyUserAppReports", {
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.allyUserProperties);
            return obj.removeAllyUserAppReports;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyUserRemoveAllyUserAppReportsBoolean", {
        get: function () {
            if (this.allyUserRemoveAllyUserAppReports == 'Y') {
                return true;
            }
            if (this.allyUserRemoveAllyUserAppReports == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "showRemoveAllyUserAppReports", {
        get: function () {
            if (this.isBranchUser) {
                var role = get(this.profile, 'allyRole');
                if ((role == ProfileAllyRole.admin) && this.allyAdminRemoveAllyUserAppReportsBoolean) {
                    return true;
                }
                if ((role == ProfileAllyRole.helpdesk) && this.helpDeskRemoveAllyUserAppReportsBoolean) {
                    return true;
                }
                if ((role == ProfileAllyRole.user) && this.allyUserRemoveAllyUserAppReportsBoolean) {
                    return true;
                }
                return false;
            }
            if (this.isDealershipUser) {
                return true;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyAdminDealerUserAddPdn", {
        /*
        GETTERS for dealerUserAddPdn from allyUserPorperties in session
        */
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.allyAdminProperties);
            return obj.dealerUserAddPdn;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyAdminDealerUserAddPdnBoolean", {
        get: function () {
            if (this.allyAdminDealerUserAddPdn == 'Y') {
                return true;
            }
            if (this.allyAdminDealerUserAddPdn == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "helpDeskDealerUserAddPdn", {
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.helpDeskProperties);
            return obj.dealerUserAddPdn;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "helpDeskDealerUserAddPdnBoolean", {
        get: function () {
            if (this.helpDeskDealerUserAddPdn == 'Y') {
                return true;
            }
            if (this.helpDeskDealerUserAddPdn == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyUserDealerUserAddPdn", {
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.allyUserProperties);
            return obj.dealerUserAddPdn;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyUserDealerUserAddPdnBoolean", {
        get: function () {
            if (this.allyUserDealerUserAddPdn == 'Y') {
                return true;
            }
            if (this.allyUserDealerUserAddPdn == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "showDealerUserAddPdn", {
        get: function () {
            if (this.isBranchUser) {
                var role = get(this.profile, 'allyRole');
                if ((role == ProfileAllyRole.admin) && this.allyAdminDealerUserAddPdnBoolean) {
                    return true;
                }
                if ((role == ProfileAllyRole.helpdesk) && this.helpDeskDealerUserAddPdnBoolean) {
                    return true;
                }
                if ((role == ProfileAllyRole.user) && this.allyUserDealerUserAddPdnBoolean) {
                    return true;
                }
                return false;
            }
            if (this.isDealershipUser) {
                return true;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyAdminEditDealerUserPendRequest", {
        /*
        GETTERS for editDealerUserPendRequest from allyUserPorperties in session
        */
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.allyAdminProperties);
            return obj.editDealerUserPendRequest;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyAdminEditDealerUserPendRequestBoolean", {
        get: function () {
            if (this.allyAdminEditDealerUserPendRequest == 'Y') {
                return true;
            }
            if (this.allyAdminEditDealerUserPendRequest == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "helpDeskEditDealerUserPendRequest", {
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.helpDeskProperties);
            return obj.editDealerUserPendRequest;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "helpDeskEditDealerUserPendRequestBoolean", {
        get: function () {
            if (this.helpDeskEditDealerUserPendRequest == 'Y') {
                return true;
            }
            if (this.helpDeskEditDealerUserPendRequest == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyUserEditDealerUserPendRequest", {
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.allyUserProperties);
            return obj.editDealerUserPendRequest;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyUserEditDealerUserPendRequestBoolean", {
        get: function () {
            if (this.allyUserEditDealerUserPendRequest == 'Y') {
                return true;
            }
            if (this.allyUserEditDealerUserPendRequest == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "showEditDealerUserPendRequest", {
        get: function () {
            if (this.isBranchUser) {
                var role = get(this.profile, 'allyRole');
                if ((role == ProfileAllyRole.admin) && this.allyAdminEditDealerUserPendRequestBoolean) {
                    return true;
                }
                if ((role == ProfileAllyRole.helpdesk) && this.helpDeskEditDealerUserPendRequestBoolean) {
                    return true;
                }
                if ((role == ProfileAllyRole.user) && this.allyUserEditDealerUserPendRequestBoolean) {
                    return true;
                }
                return false;
            }
            if (this.isDealershipUser) {
                return true;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyAdminEditAllyUserPendRequest", {
        /*
        GETTERS for editAllyUserPendRequest from allyUserPorperties in session
        */
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.allyAdminProperties);
            return obj.editAllyUserPendRequest;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyAdminEditAllyUserPendRequestBoolean", {
        get: function () {
            if (this.allyAdminEditAllyUserPendRequest == 'Y') {
                return true;
            }
            if (this.allyAdminEditAllyUserPendRequest == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "helpDeskEditAllyUserPendRequest", {
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.helpDeskProperties);
            return obj.editAllyUserPendRequest;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "helpDeskEditAllyUserPendRequestBoolean", {
        get: function () {
            if (this.helpDeskEditAllyUserPendRequest == 'Y') {
                return true;
            }
            if (this.helpDeskEditAllyUserPendRequest == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyUserEditAllyUserPendRequest", {
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.allyUserProperties);
            return obj.editAllyUserPendRequest;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyUserEditAllyUserPendRequestBoolean", {
        get: function () {
            if (this.allyUserEditAllyUserPendRequest == 'Y') {
                return true;
            }
            if (this.allyUserEditAllyUserPendRequest == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "showEditAllyUserPendRequest", {
        get: function () {
            if (this.isBranchUser) {
                var role = get(this.profile, 'allyRole');
                if ((role == ProfileAllyRole.admin) && this.allyAdminEditAllyUserPendRequestBoolean) {
                    return true;
                }
                if ((role == ProfileAllyRole.helpdesk) && this.helpDeskEditAllyUserPendRequestBoolean) {
                    return true;
                }
                if ((role == ProfileAllyRole.user) && this.allyUserEditAllyUserPendRequestBoolean) {
                    return true;
                }
                return false;
            }
            if (this.isDealershipUser) {
                return true;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyAdminRemoveDealerUser", {
        /*
        GETTERS for removeDealerUser from allyUserPorperties in session
        */
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.allyAdminProperties);
            return obj.removeDealerUser;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyAdminRemoveDealerUserBoolean", {
        get: function () {
            if (this.allyAdminRemoveDealerUser == 'Y') {
                return true;
            }
            if (this.allyAdminRemoveDealerUser == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "helpDeskRemoveDealerUser", {
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.helpDeskProperties);
            return obj.removeDealerUser;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "helpDeskRemoveDealerUserBoolean", {
        get: function () {
            if (this.helpDeskRemoveDealerUser == 'Y') {
                return true;
            }
            if (this.helpDeskRemoveDealerUser == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyUserRemoveDealerUser", {
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.allyUserProperties);
            return obj.removeDealerUser;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyUserRemoveDealerUserBoolean", {
        get: function () {
            if (this.allyUserRemoveDealerUser == 'Y') {
                return true;
            }
            if (this.allyUserRemoveDealerUser == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "showRemoveDealerUser", {
        get: function () {
            if (this.isBranchUser) {
                var role = get(this.profile, 'allyRole');
                if ((role == ProfileAllyRole.admin) && this.allyAdminRemoveDealerUserBoolean) {
                    return true;
                }
                if ((role == ProfileAllyRole.helpdesk) && this.helpDeskRemoveDealerUserBoolean) {
                    return true;
                }
                if ((role == ProfileAllyRole.user) && this.allyUserRemoveDealerUserBoolean) {
                    return true;
                }
                return false;
            }
            if (this.isDealershipUser) {
                return true;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyAdminRemoveAllyUser", {
        /*
        GETTERS for removeAllyUser from allyUserPorperties in session
        */
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.allyAdminProperties);
            return obj.removeAllyUser;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyAdminRemoveAllyUserBoolean", {
        get: function () {
            if (this.allyAdminRemoveAllyUser == 'Y') {
                return true;
            }
            if (this.allyAdminRemoveAllyUser == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "helpDeskRemoveAllyUser", {
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.helpDeskProperties);
            return obj.removeAllyUser;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "helpDeskRemoveAllyUserBoolean", {
        get: function () {
            if (this.helpDeskRemoveAllyUser == 'Y') {
                return true;
            }
            if (this.helpDeskRemoveAllyUser == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyUserRemoveAllyUser", {
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.allyUserProperties);
            return obj.removeAllyUser;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyUserRemoveAllyUserBoolean", {
        get: function () {
            if (this.allyUserRemoveAllyUser == 'Y') {
                return true;
            }
            if (this.allyUserRemoveAllyUser == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "showRemoveAllyUser", {
        get: function () {
            if (this.isBranchUser) {
                var role = get(this.profile, 'allyRole');
                if ((role == ProfileAllyRole.admin) && this.allyAdminRemoveAllyUserBoolean) {
                    return true;
                }
                if ((role == ProfileAllyRole.helpdesk) && this.helpDeskRemoveAllyUserBoolean) {
                    return true;
                }
                if ((role == ProfileAllyRole.user) && this.allyUserRemoveAllyUserBoolean) {
                    return true;
                }
                return false;
            }
            if (this.isDealershipUser) {
                return true;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyAdminViewPending", {
        /*
        GETTERS for viewPending from allyUserPorperties in session
        */
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.allyAdminProperties);
            return obj.viewPending;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyAdminViewPendingBoolean", {
        get: function () {
            if (this.allyAdminViewPending == 'Y') {
                return true;
            }
            if (this.allyAdminViewPending == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "helpDeskViewPending", {
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.helpDeskProperties);
            return obj.viewPending;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "helpDeskViewPendingBoolean", {
        get: function () {
            if (this.helpDeskViewPending == 'Y') {
                return true;
            }
            if (this.helpDeskViewPending == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyUserViewPending", {
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.allyUserProperties);
            return obj.viewPending;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyUserViewPendingBoolean", {
        get: function () {
            if (this.allyUserViewPending == 'Y') {
                return true;
            }
            if (this.allyUserViewPending == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "showViewPending", {
        get: function () {
            if (this.isBranchUser) {
                var role = get(this.profile, 'allyRole');
                if ((role == ProfileAllyRole.admin) && this.allyAdminViewPendingBoolean) {
                    return true;
                }
                if ((role == ProfileAllyRole.helpdesk) && this.helpDeskViewPendingBoolean) {
                    return true;
                }
                if ((role == ProfileAllyRole.user) && this.allyUserViewPendingBoolean) {
                    return true;
                }
                if (this.isUnregisteredBranchUser) {
                    return true;
                }
                return false;
            }
            if (this.isDealershipUser) {
                return true;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyAdminEditViewPending", {
        /*
        GETTERS for editViewPending from allyUserPorperties in session
        */
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.allyAdminProperties);
            return obj.editViewPending;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyAdminEditViewPendingBoolean", {
        get: function () {
            if (this.allyAdminEditViewPending == 'Y') {
                return true;
            }
            if (this.allyAdminEditViewPending == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "helpDeskEditViewPending", {
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.helpDeskProperties);
            return obj.editViewPending;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "helpDeskEditViewPendingBoolean", {
        get: function () {
            if (this.helpDeskEditViewPending == 'Y') {
                return true;
            }
            if (this.helpDeskEditViewPending == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyUserEditViewPending", {
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.allyUserProperties);
            return obj.editViewPending;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyUserEditViewPendingBoolean", {
        get: function () {
            if (this.allyUserEditViewPending == 'Y') {
                return true;
            }
            if (this.allyUserEditViewPending == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "showEditViewPending", {
        get: function () {
            if (this.isBranchUser) {
                var role = get(this.profile, 'allyRole');
                if ((role == ProfileAllyRole.admin) && this.allyAdminEditViewPendingBoolean) {
                    return true;
                }
                if ((role == ProfileAllyRole.helpdesk) && this.helpDeskEditViewPendingBoolean) {
                    return true;
                }
                if ((role == ProfileAllyRole.user) && this.allyUserEditViewPendingBoolean) {
                    return true;
                }
                if (this.isUnregisteredBranchUser) {
                    return true;
                }
                return false;
            }
            if (this.isDealershipUser) {
                return true;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyAdminAddSecDealerAppReports", {
        /*
        GETTERS for addSecDealerAppReports from allyUserPorperties in session
        */
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.allyAdminProperties);
            return obj.addSecDealerAppReports;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyAdminAddSecDealerAppReportsBoolean", {
        get: function () {
            if (this.allyAdminAddSecDealerAppReports == 'Y') {
                return true;
            }
            if (this.allyAdminAddSecDealerAppReports == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "helpDeskAddSecDealerAppReports", {
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.helpDeskProperties);
            return obj.addSecDealerAppReports;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "helpDeskAddSecDealerAppReportsBoolean", {
        get: function () {
            if (this.helpDeskAddSecDealerAppReports == 'Y') {
                return true;
            }
            if (this.helpDeskAddSecDealerAppReports == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyUserAddSecDealerAppReports", {
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.allyUserProperties);
            return obj.addSecDealerAppReports;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyUserAddSecDealerAppReportsBoolean", {
        get: function () {
            if (this.allyUserAddSecDealerAppReports == 'Y') {
                return true;
            }
            if (this.allyUserAddSecDealerAppReports == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "showAddSecDealerAppReports", {
        get: function () {
            if (this.isBranchUser) {
                var role = get(this.profile, 'allyRole');
                if ((role == ProfileAllyRole.admin) && this.allyAdminAddSecDealerAppReportsBoolean) {
                    return true;
                }
                if ((role == ProfileAllyRole.helpdesk) && this.helpDeskAddSecDealerAppReportsBoolean) {
                    return true;
                }
                if ((role == ProfileAllyRole.user) && this.allyUserAddSecDealerAppReportsBoolean) {
                    return true;
                }
            }
            if (this.isDealershipUser) {
                return true;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyAdminRemoveSecDealerAppReports", {
        /*
        GETTERS for removeSecDealerAppReports from allyUserPorperties in session
        */
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.allyAdminProperties);
            return obj.removeSecDealerAppReports;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyAdminRemoveSecDealerAppReportsBoolean", {
        get: function () {
            if (this.allyAdminRemoveSecDealerAppReports == 'Y') {
                return true;
            }
            if (this.allyAdminRemoveSecDealerAppReports == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "helpDeskRemoveSecDealerAppReports", {
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.helpDeskProperties);
            return obj.removeSecDealerAppReports;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "helpDeskRemoveSecDealerAppReportsBoolean", {
        get: function () {
            if (this.helpDeskRemoveSecDealerAppReports == 'Y') {
                return true;
            }
            if (this.helpDeskRemoveSecDealerAppReports == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyUserRemoveSecDealerAppReports", {
        get: function () {
            var obj = this.storeService.read(SessionManagementAttrKey.allyUserProperties);
            return obj.removeSecDealerAppReports;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyUserRemoveSecDealerAppReportsBoolean", {
        get: function () {
            if (this.allyUserRemoveSecDealerAppReports == 'Y') {
                return true;
            }
            if (this.allyUserRemoveSecDealerAppReports == 'N') {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "showRemoveSecDealerAppReports", {
        get: function () {
            if (this.isBranchUser) {
                var role = get(this.profile, 'allyRole');
                if ((role == ProfileAllyRole.admin) && this.allyAdminRemoveSecDealerAppReportsBoolean) {
                    return true;
                }
                if ((role == ProfileAllyRole.helpdesk) && this.helpDeskRemoveSecDealerAppReportsBoolean) {
                    return true;
                }
                if ((role == ProfileAllyRole.user) && this.allyUserRemoveSecDealerAppReportsBoolean) {
                    return true;
                }
            }
            if (this.isDealershipUser) {
                return true;
            }
        },
        enumerable: true,
        configurable: true
    });
    SessionManagementService.prototype.update = function (key, value) {
        switch (key) {
            case SessionManagementAttrKey.profile:
                value = assign(this.profile, value);
                break;
            case SessionManagementAttrKey.sessionDetails:
                value = assign(this.sessionDetails, value);
                break;
        }
        this.storeService.write(key, value);
        this.handleStorageChange(key);
    };
    SessionManagementService.prototype.oktaAuthorize = function (options) {
        var config = assign({
            scopes: ['openid', 'profile'],
            clientId: environment.oktaOAuth.clientId,
            sessionToken: this.sessionToken
        }, options);
        return this.oktaAuthService.requestToken(config);
    };
    SessionManagementService.prototype.authorize = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var config = assign({
                scopes: ['openid', 'profile'],
                clientId: environment.oktaOAuth.clientId,
                sessionToken: _this.sessionToken
            });
            _this.oktaAuthService.authorize(config)
                .then(function (details) { return _this.transformUserDetails(details.resource); })
                .then(function (profile) { return _this.requestSessionDetails()
                .then(function (session) {
                _this.update('sessionId', session.id);
                _this.update('profile', tslib_1.__assign({}, profile, { userId: session.userId }));
                _this.updateAnalyticsWithProfile();
                resolve();
            }).catch(function () { return resolve(); }); }).catch(function (err) { return reject(err); });
        });
    };
    SessionManagementService.prototype.generateFingerprint = function () {
        return this.oktaAuthService.generateFingerprint();
    };
    SessionManagementService.prototype.requestSessionDetails = function () {
        return this.oktaAuthService.requestSession();
    };
    SessionManagementService.prototype.requestStatus = function (userName, isPublic) {
        var _this = this;
        if (isPublic === void 0) { isPublic = false; }
        var baseUrl = environment.ndaServerURLS.workflowRegistration + (isPublic ? '/public' : '');
        var url = baseUrl + "/workflow/request-status";
        var headers = new HttpHeaders()
            .set('Content-Type', 'application/json')
            // DRP-6323 - TODO: See if header needs to be removed, implications of removing.
            .set('userName', userName);
        return this.http.get(url, { headers: headers })
            .map(function (branchRequestStatus) {
            _this.branchUserRequestStatus = branchRequestStatus;
            return branchRequestStatus;
        });
    };
    /**
     * refreshSession (Method) - Used to refresh the user session by session Id
     * If active - refresh
     * non active - throws error
     */
    SessionManagementService.prototype.refreshSession = function () {
        var _this = this;
        if (!this.hasSession) {
            return Observable.of([]);
        }
        this.updateAnalyticsWithProfile();
        var url = environment.ndaServerURLS.sessionServices + "/session/" + this.sessionId + "/refresh";
        return this.http.post(url, null, this.httpOptions)
            .pipe(catchError(function (err) {
            _this.handleDestroySession();
            return _this.handleError('refreshSession', []);
        }));
    };
    /**
     * deleteSession (Method) - Used to refresh the user session by session Id
     * If active - refresh
     * non active - throws error
     */
    SessionManagementService.prototype.deleteSession = function () {
        var _this = this;
        return this._deleteSession().pipe(flatMap(function () {
            // Check whether details for Apigee token are present. If they are not, use the registration logout URL.
            if (!!_this.oktaAuthService.getApigeeJwt()) {
                var url = environment.ndaServerURLS.sessionServices + "/session/logout";
                return _this.http.request('delete', url, {});
            }
            else {
                var url = environment.ndaServerURLS.registrationSessionServices + "/session/logout";
                return _this.http.request('delete', url, {});
            }
        }), finalize(function () {
            // this.analytics.resetData();
            _this.clearSessionDetails();
            _this.handleDestroySession();
        }));
    };
    SessionManagementService.prototype._deleteSession = function () {
        var isMitsubishiUser = includes(this.externalGroups, ExternalUserGroup.Mitsubishi);
        if (this.hasSession && (this.isBranchUser || isMitsubishiUser)) {
            return this.oktaAuthService.signout();
        }
        return Observable.of([]);
    };
    SessionManagementService.prototype.deleteSessionOktaSesion = function () {
        var _this = this;
        this.oktaAuthService.signout();
        return this._deleteSession().pipe(flatMap(function () {
            var url = environment.ndaServerURLS.registrationSessionServices + "/session/logout";
            return _this.http.request('delete', url, {});
        }), finalize(function () {
            // this.analytics.resetData();
            _this.clearSessionDetails();
            _this.storeService.delete(SessionPersistenceAttrKey.HasActiveSession);
            _this.storeService.delete(SessionManagementAttrKey.sessionId);
        }));
    };
    SessionManagementService.prototype.clearSessionDetails = function () {
        this.oktaAuthService.clearToken();
        this.oktaAuthService.clearApigeeJwt();
        this.storeService.delete('authCode');
        this.storeService.delete('verifier');
        this.storeService.delete('gateway-header');
        // Remove stored bookmarking service URL on logout.
        this.storeService.delete('redirectUrl');
        // Clears cookies for Ally User since they do not land on dealer login page after logout.
        this.clearCookies();
    };
    /*
      Attempts to remove cookies from storage on logout by overwriting them.
      Note: Cookies with HttpOnly flag set to true are not accessible via Javascript and will not be cleared.
    */
    SessionManagementService.prototype.clearCookies = function () {
        // Grabs names of all cookies.
        var cookies = document.cookie.split("; ");
        for (var c = 0; c < cookies.length; c++) {
            // Gets hostname for iteration over each subdomain.
            var d = window.location.hostname.split(".");
            while (d.length > 0) {
                var cookieEnd = '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
                var cookie = cookies[c].split(";")[0].split("=")[0];
                var cookieBase = encodeURIComponent(cookie) + cookieEnd;
                cookie = cookie + cookieEnd;
                var p = location.pathname.split('/');
                // Attempts to clear cookies with base path / if applicable.
                document.cookie = cookie + '/';
                document.cookie = cookieBase + '/';
                // Attempts to clear cookies for each subpath of path grabbed from browser window.
                while (p.length > 0) {
                    document.cookie = cookie + p.join('/');
                    document.cookie = cookieBase + p.join('/');
                    p.pop();
                }
                ;
                d.shift();
            }
        }
    };
    /**
     * allowDashboardNavigate (Method) - redirect to dashboard if login route is called with session active
     *
     */
    SessionManagementService.prototype.allowDashboardNavigate = function () {
        return this.refreshSession().map(function (response) { return !!response['resource']; });
    };
    SessionManagementService.prototype.handleStorageChange = function (key) {
        if (key == SessionManagementAttrKey.sessionId) {
            this.hasSession$.next(this.hasSession);
        }
        else if (key == SessionPersistenceAttrKey.HasActiveSession) {
            var hasActiveSession = this.storeService.read(SessionPersistenceAttrKey.HasActiveSession);
            this.hasSession$.next(hasActiveSession);
        }
    };
    SessionManagementService.prototype.handleDestroySession = function () {
        this.storeService.delete(SessionPersistenceAttrKey.HasActiveSession);
        this.storeService.delete(SessionManagementAttrKey.sessionId);
        this.hasSession$.next(this.hasSession);
    };
    SessionManagementService.prototype.transformUserDetails = function (details) {
        var profile = details.profile;
        var pdn = details.primaryDealer;
        // add birthright property
        var groups = details.groups;
        var birthright = find(groups, function (group) { return includes(values(ProfileBirthrightAccess), group.name); });
        assign(profile, { birthright: get(birthright, 'name') });
        // add ride return property
        var access = find(groups, function (group) { return includes(values(ProfileRideReturnGroup), group.name); });
        assign(profile, { rideReturnGroup: get(access, 'name') });
        // temp: determine `dealerRole` until webservice does this
        if (pdn) {
            var dealerGroup = find(groups, { name: "DLR_" + pdn }), 
            // logic changed to determine Dealer Admin.
            // Now an user can be ADMIN for either primary or secondary pdn
            dealerAdmin = filter(groups, function (dlrs) {
                return startsWith(dlrs.name, "ADM_");
            });
            var exernalGroups = map(filter(groups, function (group) { return includes(values(ExternalUserGroup), group.name); }), 'name');
            assign(profile, {
                exernalGroups: exernalGroups,
                birthright: get(birthright, 'name'),
                rideReturnGroup: get(access, 'name'),
                dealership: { pdn: pdn, name: get(dealerGroup, 'description', '') },
                dealerRole: dealerAdmin.length > 0 ? ProfileDealerRole.admin : ProfileDealerRole.user
            });
        }
        return profile;
    };
    /**
     * Updating user in digital data object
     */
    SessionManagementService.prototype.updateAnalyticsWithProfile = function () {
        this.analytics.updateUserObject(this.userObject);
    };
    Object.defineProperty(SessionManagementService.prototype, "userObject", {
        /**
         * Provides session data to map user object
         */
        get: function () {
            var attributes = {
                dealerUserId: this.profile.userId,
                userType: this.isDealershipUser ? 'Dealer' : 'Ally',
                pdn: this.checkSession('pdn'),
                dealershipName: this.checkSession('dealershipName'),
                userRole: this.profile.allyRole || this.profile.dealerRole,
            };
            return attributes;
        },
        enumerable: true,
        configurable: true
    });
    SessionManagementService.prototype.checkSession = function (session) {
        if (this.isDealershipUser) {
            switch (session) {
                case 'dealershipName': return this.profile.dealership.name;
                case 'pdn': return this.profile.dealership.pdn;
                default: return '';
            }
        }
        return '';
    };
    Object.defineProperty(SessionManagementService.prototype, "userRole", {
        /**
         * Accessor to return userRole
         */
        get: function () {
            if (this.isBranchUser) {
                return get(this.profile, 'allyRole');
            }
            else if (this.isDealershipUser) {
                return get(this.profile, 'dealerRole');
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "isHelpDesk", {
        get: function () {
            return this.userRole == ProfileAllyRole.helpdesk;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "allyRole", {
        get: function () {
            return get(this.profile, 'allyRole');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "dealerRole", {
        get: function () {
            return get(this.profile, 'dealerRole');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "isUnregisteredBranchUser", {
        get: function () {
            if (this.isBranchUser
                && !this.allyRole
                && !this.dealerRole) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SessionManagementService.prototype, "isUnregisteredDealerUser", {
        get: function () {
            if (this.isDealershipUser
                && !this.allyRole
                && !this.dealerRole) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    return SessionManagementService;
}());
export { SessionManagementService };
